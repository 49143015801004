import React, { Fragment, useContext, useEffect } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Layout from "../../Layout"
import Container from "../../Layout/Container"

import {
  GATSBY_SAXENDA_EMAIL,
  GATSBY_SAXENDA_CONTACT_NUMBER,
} from "gatsby-env-variables"

import styles from "../../Elements/utils/elements.module.scss"
import { PatientEnrollmentContext } from "../EnrollmentContext/PatientEnrollmentContext"
import { DoctorEnrollmentContext } from "../EnrollmentContext/DoctorEnrollmentContext"

const EnrollmentComplete = ({ pageContext }) => {
  const { module, nextPath } = pageContext
  const { patientEnrollmentDispatch } = useContext(PatientEnrollmentContext)
  const { doctorEnrollmentDispatch } = useContext(DoctorEnrollmentContext)

  useEffect(() => {
    if (module?.name === "doctor-enrollment")
      doctorEnrollmentDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })

    // if (module?.name === "patient-enrollment")
    //patientEnrollmentDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
  }, [patientEnrollmentDispatch, doctorEnrollmentDispatch])

  return (
    <Layout
      title={module?.title || "Thank you!"}
      seoTitle={module?.seoTitle}
      pageContext={pageContext}
    >
      <Container isCentered>
        <div className={classNames("columns", ["column"])}>
          {module?.name === "patient-enrollment" ? (
            <div className="column">
              <Fragment>
                <p className="mb-2">
                  Enrollments are not considered final until they have been
                  processed and verified by our team.
                </p>
                <p className="mb-2">
                  Once your enrollment has been verified, you may start
                  referring patients to order Liraglutide (Saxenda<sup>®</sup>)
                  and avail of discounts at{" "}
                  <a
                    href="#"
                    className="has-text-secondary has-text-weight-bold is-underlined"
                  >
                    saxenda.medgrocer.com
                  </a>
                  .
                </p>
                <p className="mb-2">
                  You can download the patient communication materials{" "}
                  <a
                    href="#"
                    className="has-text-secondary has-text-weight-bold is-underlined"
                  >
                    here
                  </a>{" "}
                  to learn more about Liraglutide (Saxenda
                  <sup>®</sup>) and the program mechanics.
                </p>
                <p className="mb-2 notification is-success px-1 has-text-centered is-light has-text-black">
                  Feel free to reach us at{" "}
                  <a
                    href="#"
                    className="has-text-secondary has-text-weight-bold"
                  >
                    {GATSBY_SAXENDA_EMAIL}
                  </a>{" "}
                  or{" "}
                  <a
                    href={`mailto:${GATSBY_SAXENDA_EMAIL}`}
                    className="has-text-secondary has-text-weight-bold"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {GATSBY_SAXENDA_CONTACT_NUMBER}
                  </a>{" "}
                  for any questions.
                </p>
              </Fragment>
            </div>
          ) : (
            <div className="column">
              <Fragment>
                <p className="mb-2">
                  Thank you for enrolling in the Liraglutide (Saxenda
                  <sup>®</sup>) Patient Support Program. This email serves as
                  the official confirmation that your enrollment has been
                  RECEIVED. Enrollments are not considered final until they have
                  been processed and verified by our team.
                </p>
                <p className="mb-2">
                  Once your enrollment has been verified, you may start
                  referring patients to order Liraglutide (Saxenda<sup>®</sup>)
                  and avail of discounts at{" "}
                  <a
                    href="saxenda.medgrocer.com"
                    target="_blank"
                    className="has-text-secondary has-text-weight-bold is-underlined"
                  >
                    saxenda.medgrocer.com
                  </a>
                  .
                </p>{" "}
                <p className="mb-2">
                  You can download the digital welcome kit{" "}
                  <a
                    href="#"
                    className="has-text-secondary has-text-weight-bold is-underlined"
                  >
                    here
                  </a>{" "}
                  to learn more about Liraglutide (Saxenda<sup>®</sup>) and the
                  program mechanics. If you have opted for email and SMS
                  communication, the link to the welcome kit will be provided
                  there also.
                </p>
                <p className="mb-2 notification is-success px-1 has-text-centered is-light has-text-black">
                  Feel free to reach us at{" "}
                  <a
                    href="#"
                    className="has-text-secondary has-text-weight-bold"
                  >
                    {GATSBY_SAXENDA_EMAIL}
                  </a>{" "}
                  or{" "}
                  <span
                    href={`mailto:${GATSBY_SAXENDA_EMAIL}`}
                    className="has-text-secondary has-text-weight-bold"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {GATSBY_SAXENDA_CONTACT_NUMBER}
                  </span>{" "}
                  for any questions.
                </p>
              </Fragment>
            </div>
          )}
        </div>
        <center>
          <Link
            to={nextPath}
            className={classNames(
              `button-size mb-1 is-secondary`,
              styles["heroCard__links"],
              styles["secondary_button"]
            )}
          >
            Back to Home Page
          </Link>
          {/* <Link to={nextPath} className="mt-2 button is-sucess is-medium">
            Back to Home Page
          </Link> */}
        </center>
      </Container>
    </Layout>
  )
}

export default EnrollmentComplete
